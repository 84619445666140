import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unavailability-screen',
  templateUrl: './unavailability-screen.component.html',
  styleUrls: ['./unavailability-screen.component.scss']
})
export class UnavailabilityScreenComponent implements OnInit {

  public content = "Puedes hacerlo todos los días de 6:00 am a 10:00 pm";

  constructor() { }

  ngOnInit(): void {
  }

}

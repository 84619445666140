import { RequestCustomer } from './../models/request-customer';
import { CustomerGateway } from './../models/gateways/customer-gateway';
import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ResponseCustomer } from '../models/response-customer';


@Injectable()

export class CustomerUseCase {
    customerSubs: Subscription;
    constructor(private gateway: CustomerGateway) { }

    getDetailsCustomer(requestCustomer: RequestCustomer): Observable<any> {
        const subject = new Subject<any>();
        if (this.customerSubs){
            this.customerSubs.unsubscribe;
        }
        this.customerSubs = this.gateway.getCustomer(requestCustomer).subscribe({
            next(data: ResponseCustomer) {
                subject.next(data);
                subject.complete();
            }, error(err: any) {
                subject.next(err);
                subject.complete();
            }
        });
        return subject.asObservable();
    }
}
<div class="bc-row bc-justify-content-center bc-align-items-center header-background-sufi">
    <div class="bc-w-auto">
        <a href="https://sufi.grupobancolombia.com/">
            <svg width="161" height="80" viewBox="0 0 161 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M43.2184 42.3172L31.6987 45.2637V33.8243L41.671 31.2822C43.6196 30.7622 45.5109 32.2644 45.5109 34.2287V39.3129C45.5109 40.7573 44.5939 41.9706 43.2184 42.3172Z" fill="white"/>
                <path d="M29.4059 57.2809L22.6431 59.1875C20.6945 59.7075 18.8032 58.2053 18.8032 56.241V51.1568C18.8032 49.7702 19.7202 48.5569 21.0957 48.2103L31.6984 45.3215V54.3922C31.6984 55.721 30.7814 56.9343 29.4059 57.2809Z" fill="white"/>
                <path d="M31.6986 33.8244L13.2442 38.7353C11.2956 39.2552 9.4043 37.7531 9.4043 35.731V30.6468C9.4043 29.2602 10.3213 28.0469 11.6968 27.7003L27.916 23.4249C29.8646 22.9049 31.7559 24.4071 31.7559 26.3714L31.6986 33.8244Z" fill="white"/>
                <path d="M54.3368 56.0676C54.2795 52.4278 54.738 50.8679 55.8842 48.6147C58.3486 49.7702 61.6154 50.8679 64.7102 50.8679C68.7794 50.8679 70.8999 49.5391 70.8999 47.2281C70.8999 45.4948 69.9829 44.2816 65.1114 42.2594C58.062 39.1974 54.6233 37.1175 54.6233 31.4555C54.6233 25.1003 60.1826 20.825 68.4928 20.825C71.7596 20.825 74.7398 21.4027 77.9493 22.3849C78.0066 25.9091 77.5481 27.6424 76.3445 29.3179C73.9374 28.1046 71.0718 27.3535 68.3782 27.3535C64.8248 27.3535 62.9909 28.6823 62.9909 30.7622C62.9909 32.7266 64.309 33.8243 68.0916 35.2687C75.9433 38.3307 79.3247 41.0462 79.3247 46.3615C79.3247 53.2944 74.1667 57.6853 64.4237 57.6853C60.7557 57.6853 57.2024 57.1076 54.3368 56.0676Z" fill="white"/>
                <path d="M107.981 29.7802L107.923 53.9301C104.542 56.1833 99.0399 57.3966 94.5696 57.3966C88.3799 57.3966 83.1646 54.97 83.1646 47.4593V31.109C85.457 30.069 88.0934 29.7802 91.2455 29.7802V46.246C91.2455 49.1347 93.1368 50.5791 96.4036 50.5791C97.6645 50.5791 98.8107 50.4058 99.8423 50.1169V31.0512C102.135 30.069 104.886 29.7802 107.981 29.7802Z" fill="white"/>
                <path d="M124.2 28.6824V29.8379H132.281C132.281 31.5134 131.135 34.8065 129.702 36.3087H124.2V57.0499H116.062V36.3087H111.993C111.993 33.9399 113.941 30.4156 116.062 29.3179V27.9313C116.062 21.9805 119.214 18.1674 126.722 18.1674C129.358 18.1674 133.141 18.6296 134.287 18.8607C134.287 22.0383 133.714 23.9448 132.51 25.3314C131.479 25.1003 128.957 24.8692 128.04 24.8692C125.232 24.8692 124.2 26.6603 124.2 28.6824Z" fill="white"/>
                <path d="M137.209 21.5183C137.209 19.2651 139.101 17.3585 141.336 17.3585C143.628 17.3585 145.52 19.2073 145.52 21.5183C145.52 23.7715 143.628 25.6781 141.336 25.6781C139.101 25.7359 137.209 23.8871 137.209 21.5183ZM137.324 30.1268C138.47 29.6646 139.846 29.3757 141.393 29.3757C142.941 29.3757 144.316 29.6646 145.405 30.1268V57.0499H137.267V30.1268H137.324Z" fill="white"/>
            </svg>
        </a>
    </div>
</div>


<div class="bc-container">
    <div class="bc-row">
        <div class="bc-col-md-6 bc-col-sm-12">
            <br id="margin-header">
            <div class="bc-container bc-row bc-align-items-center" style="height: 100%;">
                <div>
                    <h1 class="title-header">
                        Consulta y paga en línea tu crédito Sufi
                    </h1>
                    <h5 class="subtitle-header">
                        Continúa hacia abajo para comenzar
                    </h5>
                </div>
            </div>
        </div>
        <div class="bc-col-md-6" id="container-img">
            <div class="bc-container bc-row bc-align-items-end" style="height: 100%;">
                <div id="gray-square"></div>
                <img src="../../../../assets/img/header-image.png" alt="Cargando..." class="img-responsive">
            </div>
        </div>
    </div>
</div>


<div class="bc-container" >
    <div class="bc-row">
        <div class="bc-col">
            <br>
            <br>
            <hr id="line-header">
        </div>
    </div>
    <br>
</div>

import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ReferencePaymentGateway } from '../../models/gateways/paymentLink/referencePayment-gateway';
import { RequestReferencePayment } from '../../models/paymentLink/request-referencePayment';
import { ResponseReferencePayment } from '../../models/paymentLink/response-referencePayment';


@Injectable()

export class ReferencePaymentUseCase {
    refPaymentSubs: Subscription;
    constructor(private gateway: ReferencePaymentGateway) { }

    getNumberReferencePayment(requestRefPayment: RequestReferencePayment): Observable<any> {
        const subject = new Subject<any>();
        if (this.refPaymentSubs){
            this.refPaymentSubs.unsubscribe;
        }
        this.refPaymentSubs = this.gateway.getReferencePayment(requestRefPayment).subscribe({
            next(data: ResponseReferencePayment) {
                subject.next(data);
                subject.complete();
            }, error(err: any) {
                subject.next(err);
                subject.complete();
            }
        });
        return subject.asObservable();
    }
}
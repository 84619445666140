import { DatePipe } from '@angular/common';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { Component, Input, OnInit, EventEmitter, Output, ViewChild, HostListener, ElementRef } from '@angular/core';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { ResponseCustomer } from 'src/app/domain/models/response-customer';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BcRadioComponent } from '@bancolombia/design-system-web/bc-radio';
import { BcModalComponent } from '@bancolombia/design-system-web/bc-modal';
import { options } from 'src/app/domain/models/constants';


@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss']
})
export class StepTwoComponent implements OnInit {

  @ViewChild('radio') radio:BcRadioComponent;
  @Output('cancel') cancel: EventEmitter<any> = new EventEmitter();
  @Output('goPayment') goPayment: EventEmitter<any> = new EventEmitter();
  @ViewChild(BcModalComponent, { static: false }) bcModalComponent: BcModalComponent;

  @Input() customer: ResponseCustomer;
  @Input() mensajeMostrar: Boolean;

  // Variable para escribir la fecha
  dateToPay: any;

  dateValidate: any = "0001-01-01";
  stateOtherValue: string = 'default';

  // Variable para la detección de resolución en tiempo real
  public innerWidth: any;

  // Input de other value
  @ViewChild('inputOtherValue', { static: true }) public inputOtherValue: ElementRef;

// modal
    text_modal = 'Mostrar Modal';
    action = true;
    typeIcon = 'warning';
    customWidth = 'small';
    titleAlign = '';
    buttonAlignment = 'horizontal'
    selectedValue = '';
    displayButton = true;
    outerCloseTap = true;
    subTitle = '';
  //fin modal


  frmStepTwo: UntypedFormGroup;
  showErrorOtherValue:Boolean;



  constructor( private dialogService: BcDialogService, private datePipe: DatePipe ) { }

  // Función de detección de resolución en tiempo real
  @HostListener ('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 576)
      this.buttonAlignment = 'vertical';
    else
      this.buttonAlignment = 'horizontal';
  }

  createFormTwo() {
    return new UntypedFormGroup({
      radioMinimun: new UntypedFormControl('', []),
      radioPayment: new UntypedFormControl('', []),
      otherValue: new UntypedFormControl(''),
    });
  }

  onPressButton(event: any) {
    switch( Number(event) ){
      case 0:{
        this.bcModalComponent.shutDown();
        this.cancel.emit();
      }
      break;
      case 1:{
        this.bcModalComponent.shutDown();
        this.goPayment.emit(this.frmStepTwo);
      }
      break;
    }
  }

  outerClose(value: boolean) {
    this.outerCloseTap = value;
  }

  showModal() {
    this.bcModalComponent.openModal();
  }



  changeValue(event:any){
    switch( Number(event.target.value) ){
      case 1:{
        this.frmStepTwo.get("radioPayment")?.setValue("");
        this.stateOtherValue = 'default';
        this.frmStepTwo.get("otherValue")?.setValue("");
        this.showErrorOtherValue = false;
      }
      break;
      case 2:{
        this.frmStepTwo.get("radioMinimun")?.setValue("");
        this.stateOtherValue = 'default';
        this.frmStepTwo.get("otherValue")?.setValue("");
        this.showErrorOtherValue = false;
      }
      break;
      case 3:{
        this.frmStepTwo.get("radioMinimun")?.setValue("");
        this.inputOtherValue.nativeElement.value = "",
        this.showErrorOtherValue = false;
      }
      break;
    }
  }

  ngOnInit() {

    if (screen.width < 576)
      this.buttonAlignment = 'vertical';

    this.frmStepTwo = this.createFormTwo();

    if(this.customer.dateNextPayment == this.dateValidate)
      //this.customer.dateNextPayment = "-";
      this.dateToPay = "-";
    else
      this.dateToPay = this.datePipe.transform(this.customer.dateNextPayment, 'dd/MM/yyyy')

    if(this.customer.currentMinimumPayment!=0){
      this.frmStepTwo.get("radioMinimun")?.setValue("1");
    }
    else{
      this.frmStepTwo.get("radioPayment")?.setValue("2");
    }
  }


  band: boolean = false;

  public validateOtherValue(event: any) {
    if(this.frmStepTwo.get('otherValue')?.value == 0 || this.frmStepTwo.get('otherValue')?.value == null || this.frmStepTwo.get('otherValue')?.value == ""){
      this.showErrorOtherValue = true;
      this.stateOtherValue = 'error';
    }
    else {
      if(this.frmStepTwo.get('otherValue')?.value > this.customer.currentTotalPayment){
        this.showErrorOtherValue = true;
        this.stateOtherValue = 'error';
      }
      else {
        this.showErrorOtherValue = false;
        this.stateOtherValue = 'default';
      }
    }
  }

  activateOtherValue(){
    this.frmStepTwo.get("radioPayment")?.setValue("3");
    this.frmStepTwo.get("radioMinimun")?.setValue("");
  }


  open(): void {
    const dialogRef = this.dialogService.open(BcAlertComponent, {
      id: 'MyAlertId',
      type: 'error',
      text: 'Esta es una alerta de error.',
      elementRef: 'IdElementRef',
    });

    dialogRef.onResult().subscribe(
      (closed) => {},
      (dismissed) => {},
      () => {}
    );
  }

  cancellation() {
    this.cancel.emit();
  }

  getCompleteStepTwo(){
    const valorpayment = this.frmStepTwo.get('radioPayment')?.value;
    if (valorpayment == 3){
      const otherValue = this.frmStepTwo.get('otherValue')?.value.toString().replace(/,/g, '');
      if (otherValue == 0 || otherValue == null || otherValue == ""){
        this.showErrorOtherValue = true;
        this.stateOtherValue = 'error';
      }
    }
    if(this.frmStepTwo.invalid || this.showErrorOtherValue){
      return;
    }
    else{
      this.showModal();
    }
  }
}

import { DatePipe } from '@angular/common';
import { RequestPayment } from 'src/app/domain/models/request-payment';
import { ResponseCustomer } from './../../../domain/models/response-customer';
import { PaymentService } from '../../../infraestructure/driven-adapters/payment-adapter';
import { Component, HostListener, OnInit } from '@angular/core';
import { finalize } from 'rxjs';

import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { LoadingServiceService } from 'src/app/infraestructure/services/loading-service/loading-service.service';
import { GetIpService } from 'src/app/infraestructure/services/get-ip/get-ip.service';


@Component({
  selector: 'app-step-four',
  templateUrl: './step-four.component.html',
  styleUrls: ['./step-four.component.scss']
})
export class StepFourComponent implements OnInit {

  // Variable para asignar que pago se mostrara
  public paymentState: number = 1;
  // Variable para cambiar estilos en versión mobile
  public activateMobile: boolean = false;
  // Variable para asignar el tamaño del icono
  public sizeIcon: string = "3xl";
  // Variable para la detección de resolución en tiempo real
  public innerWidth: any;

  // Variables de datos de responseCustomer
  public idTransaction: any;
  public loanId: any;
  public ipAddress: any;
  public idWompi: any;
  public fechaActual: any;

  // Variable para el estado
  public state: any = "Aprobado";

  // Info final a pintar
  public infoFinal: any = {
    loanId: '',
    statusMessage: '',
    sentAt: '',
    transactionId: '',
    amountInCents: 0,
    paymentMethodType: ''
  };

  // Url de imagenes
  public imageLogo = '../../../assets/img/sufi-logo.png'
  public imageVig = '../../../assets/img/vigilado-ver.png'

  constructor( private paymentService: PaymentService, public serviceLoading: LoadingServiceService, private datePipe: DatePipe, private ipAddressService: GetIpService ) { }

  ngOnInit() {
    this.mobileOnOff();
    let ipTemporal: any = this.ipAddressService.getIPAddress()//(await this.ipAddressService.getIPAddress().toPromise())!;
    this.ipAddress = ipTemporal.ip;
    this.getDataInitial();
  }


  // Función de detección de resolución en tiempo real
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.mobileOnOff();
  }


  // Función para activar cambios visuales en version mobile
  mobileOnOff() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 992) {
      this.activateMobile = true;
    }
    else {
      this.activateMobile = false;
    }
  }

  // Función para armar solicitud y solicitar la data de la transacción realizada a Wompi
  getDataInitial() {
    const data : ResponseCustomer = JSON.parse(localStorage.getItem('infoServices')!);

    this.idTransaction = data.idTransactionInformation; //"36740";
    this.loanId = data.loanId; //"056455";
    this.idWompi = localStorage.getItem('idWompi');
    this.fechaActual = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss.SSSS');
    this.getResult();
  }


  getResult() {
    const datos: RequestPayment = {
      idTransactionInformation: parseInt(this.idTransaction), //parseInt("36740"),
      wompiTransactionId: this.idWompi, //"17622-1634673284-34950",
      ipAddress: this.ipAddress,
      dateTimeRequest: this.fechaActual, //"2020-11-10 16:18:21.9210",
      loanId: this.loanId //"00000200000064537"
    };
    this.paymentService.getPayment(datos, this.ipAddress).pipe(
      finalize(() => {
      })).subscribe(data => {
        this.infoFinal = data;
        this.serviceLoading.consulting.emit(false)
        if(this.infoFinal.statusMessage == "APPROVED"){
          this.paymentState = 1;
          this.state = "Aprobada";
        }
        else if(this.infoFinal.statusMessage == "PENDING"){
          this.paymentState = 2;
          this.state = "Pendiente";
        }
        else{
          this.paymentState = 3;
          this.state = "Rechazada";
        }
      },
      error => {
        this.paymentState = 3;
        this.state = "Rechazada";
        this.serviceLoading.consulting.emit(false)
      });
    this.serviceLoading.consulting.emit(true);
    localStorage.clear();
  }

  // Función que genera el PDF de comprobante de pago
  generatePDF(){
    const DATA = document.getElementById('toPDF')!;
    let w = 0;
    let h = 0;
    h = document.getElementById('toPDF')?.offsetHeight!
    document.getElementById('info')!.style.display = 'block';
    h = h + document.getElementById('info')?.offsetHeight! + 150;
    document.getElementById('info')!.style.display = 'none';
    w = document.getElementById('toPDF')?.offsetWidth!;
    let orientation: any;
    if(this.activateMobile){
      orientation = 'p';
    }
    else{
      orientation = 'l'
    }

    // Parametros de creación de la pantilla del archivo PDF
    const pdf = new jsPDF(orientation, 'px', [w * 1.2, h * 1.2]);

    // Captura para canvas y luego pegar a PDF
    html2canvas(DATA, {
      // Parametro de la captura
      width: w,
      height: h,
      scale: 4,
      onclone: (documentClon: any) => {
        // Insertamos en el clon, el bloque de info
        documentClon.getElementById('info')!.style.display = 'block';
      }
    }).then((canvas : any) => {
      const imgData = canvas.toDataURL('image/png', 1);
      pdf.addImage(imgData, 'PNG', w * 0.1, h * 0.2, w, h);
      pdf.addImage(this.imageLogo, 'JPEG', w * 0.1, ((h * 1.2) - 2*(h * 0.02)) - (148 * 0.9), 300 * 0.9, 148 * 0.9);
      pdf.addImage(this.imageVig, 'JPEG', w * 0.05, ((h * 1.2) - 2*(h * 0.02)) - (500 * 0.9), 24 * 0.9, 500 * 0.9);
      pdf.roundedRect(w * 0.02, h * 0.02, (w * 1.2) - 2*(w * 0.02), (h * 1.2) - 2*(h * 0.02), 30, 30, 'S');
      pdf.save('PagoCreditoSufi_Comprobante' + this.idWompi + '.pdf');
    });
  }
}

import { IConfig } from 'ngx-mask';

export const constants = {
    message_connection_error: 'No fue posible realizar la conexión para consultar los datos. Por favor intenta nuevamente.',
    message_validation_data_error: 'No hemos encontrado un crédito vigente con la información que nos das; pero tranqui, verifica los datos e ingrésalos nuevamente.'
};

export const options: Partial<IConfig> = {
    thousandSeparator: ",",
    decimalMarker: ".",
    prefix: ""
};

export const numbers = {
    number0: 0,
    number100: 100
}
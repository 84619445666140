<div class="bc-container">
    <div class="bc-row bc-justify-content-center">
        <div class="bc-col-md-10 bc-col-xs-5">
            <div id="alertErrorDiv"></div>
        </div>
    </div>


    <br>

    <app-loading-screen [hidden]="requesting == false"></app-loading-screen>

    <div [hidden]="requesting == true">
        <form [formGroup]="formPaymentLink">
            <div class="bc-row bc-justify-content-center">
                <div class="bc-col-md-6 bc-col-xs-5">
                    <label>Elige el tipo de documento</label>
                    <bc-input-select formatType="simple" [values]="optionsSelect"
                                    span="*Por favor selecciona tu tipo de documento de identidad"
                                    formControlName="documentType"
                                    id="documentType"
                                    status="{{ stateDocType }}">
                    </bc-input-select>
                </div>
            </div>
            <br>
            <div class="bc-row bc-justify-content-center">
                <div class="bc-col-md-6 bc-col-xs-5">
                    <bc-form-field>
                        <label>Ingresa el número del documento</label>
                        <input bc-input
                                aria-label="input"
                                autocomplete="off"
                                type="text" value=""
                                formControlName="documentNumber"
                                state="{{ stateDocNum }}"
                                id="documentNumber"
                                mask="99999999999" [specialCharacters]="[]"
                                #inputId
                                required/>
                        <span>*Digita el número de tu documento de identidad</span>
                    </bc-form-field>
                </div>
            </div>
            <br>
            <div class="bc-row bc-justify-content-center">
                <div class="bc-col-sm-auto bc-col-xs-5">
                    <button bc-button sizeButton="small" (click)="requestPaymentReference()">CONSULTAR</button>
                </div>
            </div>

            <re-captcha #invisibleCaptcha size="invisible" siteKey="{{ keyCaptcha }}"></re-captcha>
        </form>
    </div>

    <br><br>

</div>


<bc-modal
    title="Enlace no válido"
    typeIcon="warning"
    customWidth="small"
    textBtn="Entendido"
    outerCloseTap="false"
    hideCloseButton="true"
    (stateClick)="onPressButton($event)"
    >
    <div modalContent>
        <p>
            Por favor comunícate a través de nuestros canales de atención para solicitar un enlace nuevo.
        </p>
    </div>
</bc-modal>
import { ReferencePaymentGateway } from 'src/app/domain/models/gateways/paymentLink/referencePayment-gateway';
import { RequestReferencePayment } from 'src/app/domain/models/paymentLink/request-referencePayment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})


export class ReferencePaymentAdapter implements ReferencePaymentGateway {

    path: any;
    ipAddress: any;
    commonHeaders : any;
    urlLink: any;

    constructor(private http: HttpClient) {
        this.path = environment.getPaymentReference;
        // this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
        //     this.ipAddress = res.ip;
        // });
        this.ipAddress = "201.221.125.139";
    }

    getReferencePayment(data: RequestReferencePayment) {
        //define si es masivo o uno a uno
        const headers = this.headers(data.isMasive);
        const requestOptions = { headers: headers };
        if(data.isMasive){
            this.urlLink = environment.getPaymentReferenceMSV;
        }else{
            this.urlLink = this.path;
        }
        return this.http.post<any>(this.urlLink, data, requestOptions);
    }

    headers(isMasive: boolean): HttpHeaders {
        if (isMasive) {
            this.commonHeaders = {
                'Content-Type': 'application/json',
                'system-id': 'nu0610001',
                'Accept': 'application/json',
            };
        }
        else{
            this.commonHeaders = {
                'Content-Type': 'application/json',
                'system-id': 'nu0093001',
                'Accept': 'application/json',
                'controller-header': 'value-authorization'
            };
        }
        return new HttpHeaders(this.commonHeaders);
    }
}

import { CustomerGateway } from './../../domain/models/gateways/customer-gateway';
import { RequestCustomer } from './../../domain/models/request-customer';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})


export class CustomerAdapter implements CustomerGateway {
    headers: any;
    path: any;
    ipAddress: any;

    constructor(private http: HttpClient) {
        this.path = environment.getLoanEndpoint;
        this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
            this.ipAddress = res.ip;
        });
    }


    getCustomer(data: RequestCustomer) {
        const fechaAct = new Date();
        data.ipAddress = this.ipAddress;
        const fecha = fechaAct.getFullYear().toString() + fechaAct.getDate().toString() + (fechaAct.getMonth() + 1).toString()
        + fechaAct.getHours().toString() + fechaAct.getMinutes().toString() + fechaAct.getSeconds().toString() + fechaAct.getMilliseconds().toString();
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'message-id': fecha,
            'ip-client': this.ipAddress,
            'system-id': 'nu0093001',
            'Accept': 'application/json',
            'Authorization': 'bnUwMDkzMDAxd29tcGk='
        });
        return this.http.post<any>(this.path, data, { headers: this.headers });
    }
}

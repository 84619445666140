import { BlockHourGuard } from './infraestructure/guards/block-hour.guard';
import { HomeComponent } from './ui/payment-gateway/home/home.component';
import { UnavailabilityScreenComponent } from './ui/payment-gateway/unavailability-screen/unavailability-screen.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PaymentLinkComponent } from './ui/payment-link/payment-link.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [BlockHourGuard]
  },
  {
    path: 'blockHour',
    component: UnavailabilityScreenComponent,
    canActivate: [BlockHourGuard]
  },
  {
    path: 'pay',
    component: PaymentLinkComponent,
    canActivate: [BlockHourGuard]
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const environment = {
  production: false,
  homeUrl: '',
  blockHourUrl: 'blockHour',
  getLoanEndpoint:"#{{getLoanEndpoint}}#",
  endpointPayment: "#{{paymentEndpoint}}#",
  getPaymentReference: "#{{paymentLinkEndpoint}}#",
  getPaymentReferenceMSV: "#{{paymentMSVLinkEndpoint}}#",
  sigIntEndpoint: "#{{sigIntEndpoint}}#",
  enableRecaptcha: "#{{enableRecaptcha}}#",
  siteKeyCaptcha: "#{{recaptchaKey}}#",
  wompiKey: "#{{wompiKey}}#",
  redirectUrlWompi: "#{{urlRedirect}}#",
  hourBlockStart: "#{{hourBlockStart}}#"
};
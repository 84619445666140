import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestSigInt } from '../../models/signatureIntegrity/request-signature-integrity';
import { ResponseSigInt } from '../../models/signatureIntegrity/response-signature-integrity';
import { SignatureIntegrityGateway } from '../../models/gateways/signatureIntegrity/gateway-signature-integrity';


@Injectable()

export class SignatureIntegrityUseCase {

    constructor(private gateway: SignatureIntegrityGateway) { }

    getSigInt(data: RequestSigInt): Observable<ResponseSigInt> {
        return this.gateway.getSigInt(data);
    }
}
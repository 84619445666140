<div>

</div>

<bc-modal title="¡Lo sentimos! No pudimos completar el pago."
          titleAlign="center"
          componentId="modalSigInt"
          [isCancellable]="action"
          [typeIcon]="'error'"
          customWidth="medium"
          isCancellable="true"
          textBtn="Continuar"
          typeNotify="success"
          (stateClick)="actionModal($event)"
          displayButton="true"
          outerCloseTap="false">
  <div modalContent>
    <p class="bc-text-center">
      Porfa, inténtalo nuevamente y no te preocupes que el saldo de tu cuenta bancaria NO se vio afectado.
    </p>
  </div>
</bc-modal>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { delay, retryWhen, take, tap, timeout } from 'rxjs/operators';
import { RequestPayment } from 'src/app/domain/models/request-payment';
import { ResponsePayment } from 'src/app/domain/models/response-payment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  headers: any;
  path: any;
  private delay1000 = 1000;
  private timeoutGenerico = 4000;
  private numReintentosServicios = 3;
  private numFallos = 0;

  constructor(private http: HttpClient) {
    this.path = environment.endpointPayment;
  }

  getPayment(data: RequestPayment, ipAddress: any) {
    const fechaAct = new Date();
    const fecha = fechaAct.getFullYear().toString() + fechaAct.getDate().toString() + (fechaAct.getMonth() + 1).toString()
      + fechaAct.getHours().toString() + fechaAct.getMinutes().toString() + fechaAct.getSeconds().toString() + fechaAct.getMilliseconds().toString();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'message-id': fecha,
      'ip-client': ipAddress,
      'system-id': 'nu0093001',
      'Accept': 'application/json',
      'Authorization': 'bnUwMDkzMDAxd29tcGk='
    });
    return this.http.post<ResponsePayment>(this.path, data, { headers: this.headers }).pipe(
      timeout(this.timeoutGenerico),
      retryWhen(err => err.pipe(
        delay(this.delay1000),
        take(this.numReintentosServicios),
        tap(response => {
          if (response.status) {
            if ((response.status + '').startsWith('5') ||
              (response.status + '').startsWith('4')) {
              if ((response.status + '').startsWith('4') && !(response.status + '').startsWith('409')) {
                this.numFallos = this.numReintentosServicios;
              } else {
                this.numFallos++;
              }
              if (this.numFallos >= this.numReintentosServicios) {
                throw response;
              }
            }
          }
        })
      ))
    );
  }
}

import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  private resolutionScreen: number = 767;
  public legalesCollapsed = false;
  public enlacesCollapsed = false;
  public siguenosCollapsed = false;

  constructor() { }

  public innerWidth: any;

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if(screen.width < this.resolutionScreen){
      this.legalesCollapsed = true;
      this.enlacesCollapsed = true;
      this.siguenosCollapsed = true;
    }
  }

  @HostListener ('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < this.resolutionScreen){
      this.legalesCollapsed = true;
      this.enlacesCollapsed = true;
      this.siguenosCollapsed = true;
    }
    else{
      this.legalesCollapsed = false;
      this.enlacesCollapsed = false;
      this.siguenosCollapsed = false;
    }
  }

  colapsarUrls(columna: string){
    if(screen.width < this.resolutionScreen){
      if(columna == "legales")
        this.legalesCollapsed = !this.legalesCollapsed;
      else if(columna == 'enlaces')
        this.enlacesCollapsed = !this.enlacesCollapsed;
      else if(columna == 'siguenos')
        this.siguenosCollapsed = !this.siguenosCollapsed
    }
  }

}

export const environment = {
    production: true,
    homeUrl: '',
    blockHourUrl: 'blockHour',
    dateSixAM: 1577876400000,
    dateTenPM: 1577934000000,
    getLoanEndpoint:"https://sufi-ext-dev.apps.ambientesbc.com/boton-recaudos-sufi-mig/pagos/customer/getloan",
    endpointPayment: "https://sufi-ext-dev.apps.ambientesbc.com/boton-recaudos-sufi-mig/pagos/payment",
    getPaymentReference: "https://sufi-ext-dev.apps.ambientesbc.com/boton-recaudos-sufi-mig/links/generate/get",
    getPaymentReferenceMSV: "https://sufi-ext-dev.apps.ambientesbc.com/acceso-msv-sufi-mig/acceso-msv/checkhistorical/searchbykeyext",
    sigIntEndpoint: "https://sufi-ext-dev.apps.ambientesbc.com/orquestador-recaudo-enlinea",
    siteKeyCaptcha: "6LeeCospAAAAABmfqWO3RqJUfVzvNeix2IaAehg1",
    wompiKey: "pub_test_pC1M26EegRmZ4Z8Al1idYorfgNvC21kL",
    redirectUrlWompi: "https://pagar.sufi-dev.apps.ambientesbc.com",
  };
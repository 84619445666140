import { CustomerUseCase } from './../../domain/usecases/customer-usecase';
import { RequestCustomer } from './../../domain/models/request-customer';
import { ReferencePaymentUseCase } from './../../domain/usecases/paymentLink/referencePayment-usecase';
import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { RequestReferencePayment } from 'src/app/domain/models/paymentLink/request-referencePayment';
import { BcDialogRef, BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { environment } from 'src/environments/environment';
import { BcModalComponent } from '@bancolombia/design-system-web/bc-modal';
import { RecaptchaComponent } from 'ng-recaptcha';
import { DatePipe } from '@angular/common';
import { DataFromPayLinkService } from 'src/app/infraestructure/services/customer-from-paymentlink/data-from-pay-link.service';

@Component({
  selector: 'app-payment-link',
  templateUrl: './payment-link.component.html',
  styleUrls: ['./payment-link.component.scss']
})
export class PaymentLinkComponent implements OnInit, AfterViewInit {

  // Modal declarado
  @ViewChild(BcModalComponent, { static: false }) bcModalComponent: BcModalComponent;
  // Recaptcha
  @ViewChild('invisibleCaptcha') captcha: RecaptchaComponent;
  tokenSubscription: Subscription;

  // Variable para activar el componente loading
  public requesting: boolean = true;

  // Formulario
  formPaymentLink: FormGroup;
  // Datos de id en URL para consultar la referencia de pago
  public urlTree: any;
  public idToRequest: any;
  public isMasive: boolean = false;
  // Consumo de microservicio para get payment reference
  public refPayment: Subscription;
  // Variable donde guardaremos el valor de la referencia de pago
  private numRefPay: string = "";

  // Captcha
  keyCaptcha: string;

  // Input select
  optionsSelect = [
    {
      label: 'Cédula de ciudadanía',
      value: 'CC'
    },
    {
      label: 'Cédula de extranjería',
      value: 'CE'
    },
    {
      label: 'NIT',
      value: 'NIT'
    }
  ];

  // Variables para asignar estato de error si los inputs estan vacios
  stateDocType: string = "";
  stateDocNum: string = "";


  constructor(
    private router: Router,
    private refPaymentUseCase: ReferencePaymentUseCase,
    private customerUseCase: CustomerUseCase,
    private dialogService: BcDialogService,
    private cdRef: ChangeDetectorRef,
    private datePipe: DatePipe,
    private sendToHome: DataFromPayLinkService
    ) {
    this.keyCaptcha = environment.siteKeyCaptcha;
  }

  ngOnInit(): void {
    // Creación de formulario
    this.formPaymentLink = this.createForm();

    // Adquisición de id dado por URL
    this.urlTree = this.router.parseUrl(this.router.url);
    this.idToRequest = this.urlTree.queryParams['id'] ? this.urlTree.queryParams['id'] : this.urlTree.queryParams['idm'];
    this.isMasive = !!this.urlTree.queryParams['idm'];
    if(this.idToRequest == undefined )
      this.router.navigate(['']);
  }

  ngAfterViewInit(): void {
    this.getReferencePayment();
    this.cdRef.detectChanges();
  }

  // Función que consulta la referencia de pago con el id dado en URL
  getReferencePayment(){
    this.requesting = true;

    const dataToRequest: RequestReferencePayment = {
      id: this.idToRequest,
      ipAddress: "255.255.255.255",
      isMasive: this.isMasive
    }

    //console.log("Datos a consultar: ", dataToRequest);

    this.refPaymentUseCase.getNumberReferencePayment(dataToRequest).subscribe(
      {
        next: (data) => {
          if(this.isMasive){
            data = data.body[0];
          }
          this.requesting = false;
          //console.log("Respuestaaaa: ", data);
          if(data.status != 200)
            this.bcModalComponent.openModal();
          else
            this.numRefPay = data.paymentReference;
        },
        error: (err) => {
          this.requesting = false;
          //console.log("Error: ", err);
          this.messageAlertError = 'No fue posible realizar la conexión para consultar los datos. Por favor intenta nuevamente';
          this.openAlertError();
        },
      }
    );
  }

  // Funcion para crear el formulario
  createForm() {
    return new FormGroup({
      documentType: new FormControl('', Validators.required),
      documentNumber: new FormControl('', [Validators.minLength(6), Validators.required, Validators.maxLength(11)])
    });
  }

  // Función para consultar la referencia de pago con el formulario diligenciado
  requestPaymentReference(){
    if(this.formPaymentLink.valid){
      this.executeCaptcha().subscribe((token) =>{
        this.requesting = true;
        if (token != null && token != '') {

          //console.log("Recaptcha exitoso!")
          const fechaAct = this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss.SSSS");
          const datos: RequestCustomer = {
            documentType: this.formPaymentLink.value.documentType,
            documentNumber: this.formPaymentLink.value.documentNumber,
            paymentReference: this.numRefPay,
            agreeTermsAndConditions: true,
            recaptcha: token,
            dateTimeRequest: fechaAct != null ? fechaAct : new Date().toDateString()
          };
          this.customerUseCase.getDetailsCustomer(datos).subscribe({
            next: (data) => {
              this.requesting = false;
              //console.log("Data de customer: ", data);
              if(data.error){
                if (data.error.error == '409' || data.error.error == '404') {
                  this.messageAlertError = 'No hemos encontrado un crédito vigente con la información que nos das; pero tranqui, verifica los datos e ingrésalos nuevamente.';
                  this.openAlertError();
                }
                else if(data.error.error == '504'){
                  this.messageAlertError = 'No fue posible realizar la conexión para consultar los datos. Por favor intenta nuevamente';
                  this.openAlertError();
                }
              }
              else{
                this.sendToHome.changeMessage(data);
                this.router.navigate(['']);
              }
            },
            error: (err) => {
              this.requesting = false;
              //console.log("Error customer: ", err);
            }
          });
        }
        else
          this.requesting = false;
      });
    }
    else{
      if(this.formPaymentLink.value.documentType == ""){
        this.stateDocType = "error";
      }
      if(this.formPaymentLink.value.documentNumber == "")
        this.stateDocNum = "error";
    }
  }

  // Alert
  dialogError: BcDialogRef;
  alertErrorCreado: boolean = false;
  messageAlertError: string = 'No hemos encontrado un crédito vigente con la información que nos das; pero tranqui, verifica los datos e ingrésalos nuevamente.';

  // Función para abrir el alert cuando el microservicio responda error
  openAlertError(): void {
    if (!this.alertErrorCreado) {
      //console.log("Alert creado");
      this.dialogError = this.dialogService.open(BcAlertComponent, {
        type: 'warning',
        title: this.messageAlertError,
        inline: true,
        dismissible: false,
        elementRef: 'alertErrorDiv',
      });
      this.alertErrorCreado = true;

      this.dialogError.onResult().subscribe(
        (closed) => this.closedError()
      );
    }
  }

  closedError() {
    this.alertErrorCreado = false;
  }

  // Función del boton del modal
  onPressButton(event: any){
    this.bcModalComponent.shutDown();
    this.router.navigate(['']);
  }


  // Recaptcha
  public executeCaptcha() {
    if (environment.production) {
      this.captcha.execute();
      let subject = new Subject<string>();
      if(this.tokenSubscription)
        this.tokenSubscription.unsubscribe();
      this.tokenSubscription = this.captcha.resolved.subscribe( (token) => {
        if(token != null && token != ''){
          subject.next(token);
          subject.complete();
          this.resetCaptcha();
        }
      });
      return subject.asObservable();
    }
    let subject = new BehaviorSubject({} as string)
    subject.next('token');
    return subject.asObservable();
  }

  public resetCaptcha() {
    if (environment.production){
      this.captcha.reset();
    }
  }

}

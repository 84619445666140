import { Injectable } from '@angular/core';
import { numbers } from 'src/app/domain/models/constants';
import { DataToWidgetWompi } from 'src/app/domain/models/dataToWidgetWompi';
import { CheckoutWompiGateway } from 'src/app/domain/models/gateways/checkout-wompi-gateway';
import { environment } from "src/environments/environment";


declare let WidgetCheckout: any;
@Injectable({
    providedIn: 'root'
})


export class CheckoutWompiAdapter implements CheckoutWompiGateway {
  // widget wompi
  checkout: any;
  // Variable con la key de activacción de Wompi
  keyWompi: any;
  // Variable con la URL de redireccionamiento al finalizar la transacción
  redirectUrl: any;

  constructor() {
    this.keyWompi = environment.wompiKey;
    this.redirectUrl = environment.redirectUrlWompi;
  }

  // Función que crea un widget nuevo
  checkoutWidget(dataToWompi: DataToWidgetWompi): void {
    const Widget = this.getInstance();

    // Se revisa si vienen datos de precarga
    if (dataToWompi.email == null){
      dataToWompi.email = " ";
    }
    if (dataToWompi.phone == null){
      dataToWompi.phone = " ";
    }
    if (dataToWompi.name == null){
      dataToWompi.name = " ";
    }

    // Creación del widget
    this.checkout =  new Widget({
      currency: dataToWompi.currency,
      amountInCents: Math.round(dataToWompi.amountInCents * numbers.number100),
      reference: dataToWompi.reference,
      publicKey: this.keyWompi,
      redirectUrl: this.redirectUrl,
      signature: {
        integrity: dataToWompi.signature
      },
      customerData: {
        email: dataToWompi.email,
        fullName: dataToWompi.name,
        phoneNumber: Number(dataToWompi.phone).toString(),
        phoneNumberPrefix: '+57'
      }
    });

    // Abrir widget
    this.checkout.open(function (result:any) {
      const transaction = result.transaction;
      let url = transaction.redirectUrl;
      let id = transaction.id;
      window.location.href = url + "?id=" + id;
    });
  }

  getInstance(){
    return WidgetCheckout;
  }
}
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { numbers } from 'src/app/domain/models/constants';

@Injectable({
  providedIn: 'root'
})
export class BlockHourGuard  {

  constructor( private router: Router ){}

  canActivate( next: ActivatedRouteSnapshot ): boolean {
    // Obtener la hora actual en Colombia (UTC-5)
    const currentTime = new Date();
    const colombiaTime = new Date(currentTime.toLocaleString('en-US', { timeZone: 'America/Bogota' }));

    const currentHour = colombiaTime.getHours();

    const hourBlockStart = Number(environment.hourBlockStart);

    if(next.routeConfig?.path != environment.blockHourUrl){
      // Restricción: Entre las 10:00 PM (22:00) y 11:00 PM (23:00)
      if (currentHour == hourBlockStart ) {
        // Si está dentro del horario restringido, denegar el acceso
        this.router.navigate([environment.blockHourUrl]);
        return false;
      }
    }
    else if(next.routeConfig?.path === environment.blockHourUrl){
      if (currentHour != hourBlockStart ) {
        // Si está dentro del horario restringido, denegar el acceso
        this.router.navigate(['']);
        return false;
      }
    }

    // Permitir el acceso fuera del horario restringido
    return true;
  }
}
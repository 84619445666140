import { Injectable } from '@angular/core';
import { CheckoutWompiGateway } from '../models/gateways/checkout-wompi-gateway';
import { DataToWidgetWompi } from '../models/dataToWidgetWompi';


@Injectable()

export class CheckoutWompiUseCase {

    constructor(private gateway: CheckoutWompiGateway) { }

    checkoutWidget(dataToWompi: DataToWidgetWompi){
        this.gateway.checkoutWidget(dataToWompi);
    }
}
import { RequestReferencePayment } from 'src/app/domain/models/paymentLink/request-referencePayment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SignatureIntegrityGateway } from 'src/app/domain/models/gateways/signatureIntegrity/gateway-signature-integrity';
import { RequestSigInt } from 'src/app/domain/models/signatureIntegrity/request-signature-integrity';


@Injectable({
    providedIn: 'root'
})


export class SignatureIntegrityAdapter implements SignatureIntegrityGateway {

    // Variable con el path de la firma de integridad
    pathSigInt: string;

    constructor(private http: HttpClient) {
        this.pathSigInt = environment.sigIntEndpoint;
      }

    getSigInt(data: RequestSigInt){
        return this.http.post<any>(this.pathSigInt + '/firmaintegridad/api/v1/signatureRequest', data);
    }
}

import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-unavailability-screen',
  templateUrl: './unavailability-screen.component.html',
  styleUrls: ['./unavailability-screen.component.scss']
})
export class UnavailabilityScreenComponent implements OnInit {

  // Variable para activar funcionalidades en mobile
  public activateMobile = false;
  // Variable para la detección de resolución en tiempo real
  public innerWidth: any;

  constructor() { }

  ngOnInit(): void {
    this.mobileOnOff();
  }

  // Función de detección de resolución en tiempo real
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.mobileOnOff();
  }

  // Función para activar diseños mobile
  public mobileOnOff() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 575) {
      this.activateMobile = true;
    }
    else {
      this.activateMobile = false;
    }
  }

}

<div *ngIf="paymentState == 1">
    <div class="bc-row bc-justify-content-center bc-align-items-center">
        <div class="bc-col-auto">
            <bc-icon size="{{ sizeIcon }}" name="successful"></bc-icon>
        </div>
        <div class="bc-col-xs-5 bc-col-md-auto" [ngClass]="{ 'bc-mt-4': activateMobile }">
            <div class="title">¡Buenas noticias! El pago fue exitoso</div>
        </div>
    </div>

    <br>

    <div class="bc-row bc-justify-content-center bc-align-items-center">
        <div class="bc-col-auto">
            <bc-icon size="md" name="info"></bc-icon>
        </div>
        <div class="bc-col-xs-4 bc-col-md-6">
            <div class="small">Esta transacción se verá reflejada en tu crédito un día hábil luego de haber realizado el pago</div>
        </div>
    </div>
</div>

<div *ngIf="paymentState == 2">
    <div class="bc-row bc-justify-content-center bc-align-items-center">
        <div class="bc-col-auto">
            <bc-icon size="{{ sizeIcon }}" name="time"></bc-icon>
        </div>
        <div class="bc-col-xs-5 bc-col-md-auto" [ngClass]="{ 'bc-mt-4': activateMobile }">
            <div class="title">Tranqui, tu pago viene en camino...</div>
        </div>
    </div>

    <br>

    <div class="bc-row bc-justify-content-center bc-align-items-center">
        <div class="bc-col-auto">
            <bc-icon size="md" name="info"></bc-icon>
        </div>
        <div class="bc-col-xs-4 bc-col-md-6">
            <div class="small">Tu pago se encuentra en estado pendiente, si finaliza exitosamente, podrás consultarlo en un día hábil. Si no finaliza exitosamente podrás volver a intentarlo</div>
        </div>
    </div>
</div>

<div *ngIf="paymentState == 3">
    <div class="bc-row bc-justify-content-center bc-align-items-center">
        <div class="bc-col-auto">
            <bc-icon size="{{ sizeIcon }}" name="remove"></bc-icon>
        </div>
        <div class="bc-col-xs-5 bc-col-md-auto" [ngClass]="{ 'bc-mt-4': activateMobile }">
            <div class="title">¡Lo sentimos! No fue posible realizar el pago</div>
        </div>
    </div>

    <br>

    <div class="bc-row bc-justify-content-center bc-align-items-center">
        <div class="bc-col-auto">
            <bc-icon size="md" name="info"></bc-icon>
        </div>
        <div class="bc-col-xs-4 bc-col-md-6">
            <div class="small">Por inconvenientes mientras realizabas la transacción, no se completó el pago. ¡Pero tranqui! Puedes intentar nuevamente. </div>
        </div>
    </div>
</div>

<br><br>

<div class="bc-row bc-justify-content-center bc-align-items-center" style="height: 35px;">
    <div class="bc-col-xs-5 bc-col-sm-10 col-gray">
        <div class="bc-row bc-justify-content-center bc-align-items-center" style="height: 35px;">
            <div class="bc-col-auto title-white">
                ESTE ES EL RESUMEN DE TU PAGO
            </div>
        </div>
    </div>
</div>
<div class="bc-row bc-justify-content-center">
    <div class="bc-col-xs-5 bc-col-md-10 bc-col-lg-3">
        <bc-form-field>
            <label>Número de crédito:</label>
            <input bc-input aria-label="input" type="text" value="{{ infoFinal.data.loanId }}" required readonly/>
        </bc-form-field>
    </div>
    <div class="bc-col-xs-5 bc-col-md-10 bc-col-lg-4">
        <bc-form-field>
            <label>Fecha y hora de la transacción:</label>
            <input bc-input aria-label="input" type="text" value="{{ infoFinal.sent_at | date: 'dd/MM/yyyy
            hh:mm:ss aaa' }}" required readonly/>
        </bc-form-field>
    </div>
    <div class="bc-col-xs-5 bc-col-md-10 bc-col-lg-3">
        <bc-form-field>
            <label>Comprobante de pago:</label>
            <input bc-input aria-label="input" type="text" value="{{ idWompi }}" required readonly/>
        </bc-form-field>
    </div>
</div>
<div class="bc-row" [ngClass]="{ 'bc-justify-content-center': activateMobile }">
    <div class="bc-col-xs-5 bc-col-md-10 bc-col-lg-3 bc-offset-lg-1">
        <bc-form-field>
            <label>Estado de la transacción:</label>
            <input bc-input aria-label="input" type="text" value="{{ state }}" required readonly/>
        </bc-form-field>
    </div>
    <div class="bc-col-xs-5 bc-col-md-10 bc-col-lg-3" *ngIf="paymentState != 3">
        <bc-form-field>
            <label>Valor de la transacción:</label>
            <input id="valor" bc-input aria-label="input" type="text" value="{{ infoFinal.data.amountInCents / 100 | currency:'$':'symbol':'1.2-2' }}" readonly/>
        </bc-form-field>
    </div>
</div>

<br>

<div class="bc-row bc-justify-content-center">
    <div class="bc-col-10">
        <bc-alert
            title="Aprende con Sufi:"
            text="Para tener tus cuentas claras, te recomendamos descargar el comprobante y así tener el historial de pagos de tu crédito."
            type="info"
            [inline]="true"
            [dismissible]="false"
        ></bc-alert>
    </div>
</div>

<br>

<div style="display: none;" id="info">
    <div class="bc-row bc-justify-content-center bc-align-items-center">
        <div class="bc-col-auto">
            <bc-icon bc-icon size="md">info</bc-icon>
        </div>
        <div class="bc-col-xs-4 bc-col-md-8 bc-col-lg-7 bc-col-xl-6">
            <div class="small">
                Si tienes alguna duda o quieres recibir asesoría, contáctanos, vamos a ayudarte. <br>
                <strong>Audio Sufi:</strong> Linea Nacional 01 8000 517 834 <br>
                <strong>Líneas en Bogotá:</strong> 601 444 66 00, <strong>Medellín</strong> 604 510 78 80 y <strong>Cali</strong> 602 554 05 85
            </div>
        </div>
    </div>
</div>

<br><br><br>
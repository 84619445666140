import { numbers } from './../../../domain/models/constants';
import { Component, Input,  OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BcModalComponent } from '@bancolombia/design-system-web/bc-modal';
import { DataFormPayment } from 'src/app/domain/models/dataFormPayment';
import { DataToWidgetWompi } from 'src/app/domain/models/dataToWidgetWompi';
import { ResponseCustomer } from 'src/app/domain/models/response-customer';
import { RequestSigInt } from 'src/app/domain/models/signatureIntegrity/request-signature-integrity';
import { ResponseSigInt } from 'src/app/domain/models/signatureIntegrity/response-signature-integrity';
import { CheckoutWompiUseCase } from 'src/app/domain/usecases/checkout-wompi-usecase';
import { SignatureIntegrityUseCase } from 'src/app/domain/usecases/signatureIntegrity/signature-integrity-usecase';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.scss']
})
export class StepThreeComponent implements OnInit {

  @Input() customer: ResponseCustomer;
  @Input() form: UntypedFormGroup;
  @Input() step: any;
  // Modal de falla de firma de integridad
  @ViewChild(BcModalComponent, { static: false }) bcModalComponent: BcModalComponent;

  // Variable con la info para el widget de Wompi
  public infoCreditoToWompi: DataToWidgetWompi;

  // widget wompi
  showErrorOtherValue:Boolean;
  stepThree:any=3;
  // fin widget

  action = false;

  constructor(private wompi: CheckoutWompiUseCase, private serviceSigInt: SignatureIntegrityUseCase) { }

  ngOnInit(): void {
    if (this.step === this.stepThree){
      this.openWompi();
    }
  }

  // Función que crea toda la data necesaria para el llamado y creación del widget
  openWompi(){
    let amount: any;
    if(this.form.get('radioMinimun')?.value === '1'){
      amount = this.customer.currentMinimumPayment;
    }
    else if(this.form.get('radioPayment')?.value === '2'){
      amount = this.customer.currentTotalPayment;
    }
    else if (this.form.get('radioPayment')?.value === '3'){
      amount = this.form.get('otherValue')?.value.replace(/^\D+/g, '').replace(',', '').replace(',', '').replace(',', '');;
    }

    const dataForm: DataFormPayment = JSON.parse(localStorage.getItem('dataFormPayment') || '{}')

    const dataToSigInt: RequestSigInt = {
      idConsumer: 1,
      idChannel: 1,
      currency: "COP",
      amount: Math.round(amount * numbers.number100).toString(),
      reference: this.customer.idPaymentWompi,
      documentNumber: dataForm.documentNumber
    }

    this.serviceSigInt.getSigInt(dataToSigInt).subscribe({
      next: (data: ResponseSigInt) => {
        if(data.status == "OK"){
          this.infoCreditoToWompi = {
            signature: data.signature,
            currency: "COP",
            amountInCents: amount,
            reference: dataToSigInt.reference,
            email: this.customer.email!,
            name: this.customer.name!,
            phone: this.customer.phone!,
            documentNumber: dataForm.documentNumber,
            documentType: dataForm.documentType
          }
          this.wompi.checkoutWidget(this.infoCreditoToWompi);
        }
        else{
          this.bcModalComponent.openModal();
        }
      },
      error: (err) => {
          console.log("Error en firma de integridad");
          this.bcModalComponent.openModal();
      },
    });
  }

  // Función que se ejecuta para abrir el modal en caso de que firma de integridad falle
  actionModal(event: any){
    this.bcModalComponent.shutDown();
    document.location.href= environment.homeUrl;
  }

}
